<template>
  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Добавить новый проект
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />
      </div>

      <validation-observer
          v-slot="{invalid}"
      >
        <!-- Форма -->
        <b-form
            class="p-2"
            @submit.prevent
        >

          <BlInput placeholder="Вечера близ океана"
                   label="Название проекта"
                   rules="required"
                   name="name"
                   v-model="project.title"></BlInput>

          <!-- Кнопки -->
          <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="button"
                @click="addNewProject"
                :disabled="invalid"
            >
              Добавить
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              Отменить
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import BlInput from "@/components/controls/BlInput";
import '@/libs/custom-validate'
import api from "@/api";
import utils from "@/utils";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BlInput,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    addNewProject() {
      this.$emit('addNewProject', {title: this.project.title})
    },
  },
  directives: {
    Ripple,
  },
  props: ['isActive'],
  data() {
    return {
      project: {
        title: null
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
